import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Global Import
import "../global/styles/normalize.scss";
import "../global/styles/globals.scss";
import MobileDetect from "mobile-detect";
import config from "./js/config";
import ScrollPicker from "vue3-scroll-picker";
import i18n from "./i18n";

const app = createApp(App);

const isSSR = typeof window === "undefined";

if (!isSSR) {
  let fullPath = window.location.pathname.split("/");
  let locale = fullPath[1];
  if (locale === "") {
    locale = config.defaultLocale;
  }
  store.commit("changeLocaleHandler", locale);
}

//detect is mobile or not using user agent
const md = new MobileDetect(window.navigator.userAgent);
store.commit("deviceDetectHandler", !!md.mobile());

app.use(i18n);
app.use(router);
app.use(store);
app.use(ScrollPicker);

app.mount("#app");
