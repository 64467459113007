export default {
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "HOW_TO_PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Play"])},
  "GAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "ETHERSCAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etherscan"])},
  "TRONSCAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tronscan"])},
  "ETHERSCAN_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etherscan Lottery 5 Minutes"])},
  "TRONSCAN_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tronscan Lottery 2 Minutes"])},
  "LOTTERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lottery"])},
  "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "SECONDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])},
  "NEXT_DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Draw Number"])},
  "DRAW_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw Number"])},
  "PAST_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Results"])},
  "QUICK_DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Draw Time"])},
  "FAIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair Play"])},
  "EASY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy to Play"])},
  "DRAW25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw every 2mins and 5mins"])},
  "RESULTS_GENERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results generate directly from block information"])},
  "PICK_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick 5 numbers from 0-9"])},
  "PRIVACY_POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "COOKIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
  "LEGAL_ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Advice"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "NEXT_DRAW_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Draw Number"])},
  "NEXT_DRAW_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Draw In"])},
  "ENGLISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "CHINESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "RIGHTS_RESERVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
  "DRAWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawn"])},
  "DRAWN2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "LEFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left"])},
  "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])},
  "HISTORY_RESULTS_ETHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Results: results updated approximately every 5 minutes"])},
  "HISTORY_RESULTS_TRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Results: results updated approximately every 2 minutes"])},
  "DATE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Selection"])},
  "BLOCK_HEIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Height"])},
  "NUMBERS_DRAWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers Drawn"])},
  "DRAW_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw Time"])},
  "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
  "HOW_TO_PLAY_ETHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Play Etherscan L..."])},
  "HOW_TO_PLAY_TRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Play Tronscan L..."])},
  "ETHER_CONTENT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily draws are started from (GMT+8) 00:05 until 00:00, draws every 5-minute intervals, total of 288 draws per day, inclusive of empty results."])},
  "ETHER_CONTENT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each draw contains 5 numbers from 0-9, the numbers can be repeated."])},
  "ETHER_CONTENT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Drawn Numbers are being generated from Block Height’s Hash Number received in https://etherscan.io/"])},
  "ETHER_CONTENT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A particular Block Height, in which being received soonest after the next Draw Time, will be taken to churn the next draw number using its Hash number."])},
  "ETHER_CONTENT4_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" For example: If the hash number from the Block #14877629 is “0xe03b8b3afcea69500408226e4d8b81c60a9879c750b36ea047fad9"])},
  "ETHER_CONTENT4_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", the system would pick up the last 5 digits from the end of the hash number. In this case the number being picked up are: 3,0,6,9,3."])},
  "ETHER_CONTENT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thus, the Numbers Drawn will be"])},
  "ETHER_CONTENT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There will be a scenario where the Draw Number could have empty result."])},
  "ETHER_CONTENT6_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the draw result for 00:05:00 comes in at 00:09:59, the draw is still valid."])},
  "ETHER_CONTENT6_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, if the draw result for 00:05:00 comes in at 00:10:00 or after, the draw in 00:05:00 will not have result. "])},
  "ETHER_CONTENT7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to verify Draw Results:"])},
  "ETHER_CONTENT7_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the block height number in the result page, it will redirect you to the block detail page at https://etherscan.io/"])},
  "ETHER_CONTENT7_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check on the Block Height, make sure it is the same block height for the draw you would like to check."])},
  "ETHER_CONTENT7_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click see more to check the hash number."])},
  "ETHER_CONTENT7_C_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down to click see more to check the hash number."])},
  "ETHER_CONTENT7_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change preferred language in https://etherscan.io/, scroll down to the bottom of the page to select “Preferences”."])},
  "ETHER_CONTENT7_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then at the “Language” section, select your preferred language."])},
  "ETHER_CONTENT7_F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click “Save Preferences” to apply changes."])},
  "TRON_CONTENT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily draws are started from (GMT+8) 00:02 until 00:00, draws every 2-minute intervals, total of 720 draws per day, inclusive of empty results."])},
  "TRON_CONTENT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each draw contains 5 numbers from 0-9, the numbers can be repeated."])},
  "TRON_CONTENT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Drawn Numbers are being generated from Block Height’s Hash Number received in https://tronscan.org/"])},
  "TRON_CONTENT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A particular Block Height, in which being received soonest after the next Draw Time, will be taken to churn the next draw number using its Hash number."])},
  "TRON_CONTENT4_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example: If the Hash number from Block #41145038 is “000000000273d2ce68de4c924e269139a6ad6a4c42a260bd7b41d4da3a2"])},
  "TRON_CONTENT4_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", the system would pick up the last 5 digits from the end of the hash number. In this case the numbers being picked up are: 5,2,1,9,3"])},
  "TRON_CONTENT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thus, the Numbers Drawn will be"])},
  "TRON_CONTENT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There will be a scenario where the Draw Number could have empty result."])},
  "TRON_CONTENT6_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the draw result for 00:02:00 comes in at 00:03:59, the draw is still valid."])},
  "TRON_CONTENT6_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, if the draw result for 00:02:00 comes in at 00:04:00, the draw in 00:02:00 will not have result."])},
  "TRON_CONTENT7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to verify Draw Results:"])},
  "TRON_CONTENT7_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the block height number in the result page, it will redirect you to the block detail page at https://tronscan.org/"])},
  "TRON_CONTENT7_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check on the Block Height, make sure it is the same block height for the draw you would like to check."])},
  "TRON_CONTENT7_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the copy button on Block Hash and paste it on a note to check the full hash number."])},
  "TRON_CONTENT7_C_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click see more to check the hash number."])},
  "TRON_CONTENT7_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change preferred language in https://tronscan.org/, scroll down to the bottom of the page and select preferred language"])},
  "TRON_CONTENT7_D_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change preferred language in https://tronscan.org/, click on the hamburger icon on the top right."])},
  "TRON_CONTENT7_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then click on the language to change to preferred language."])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "TU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
  "WE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We"])},
  "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Th"])},
  "FRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
  "SU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "JAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "FEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
  "MAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "APR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "MAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "JUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "JUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
  "AUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "SEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sept"])},
  "OCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
  "NOV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
  "DEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])},
  "JAN_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "FEB_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "MAR_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "APR_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "MAY_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "JUN_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "JUL_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "AUG_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "SEPT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "OCT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "NOV_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "DEC_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "SET_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Date"])},
  "SET_DATE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
  "WHENLOTTERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When Blockchain Meets Lottery"])},
  "23HOURSADAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23hours a day of fun, draws every 2 and 5min!"])},
  "PICK5LUCKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick 5 lucky numbers to win great prizes!"])}
}