export default {
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "HOW_TO_PLAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩法"])},
  "GAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏"])},
  "ETHERSCAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以太时时彩"])},
  "TRONSCAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波场时时彩"])},
  "ETHERSCAN_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以太时时彩"])},
  "TRONSCAN_MENU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波场时时彩"])},
  "LOTTERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彩票"])},
  "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
  "SECONDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
  "NEXT_DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个开奖期数"])},
  "DRAW_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期数"])},
  "PAST_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖历史"])},
  "QUICK_DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速"])},
  "FAIR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公正"])},
  "EASY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简易"])},
  "DRAW25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每期2分钟/5分钟"])},
  "RESULTS_GENERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据采用区块链哈希值"])},
  "PICK_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经典时时彩玩法"])},
  "PRIVACY_POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
  "COOKIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缓存"])},
  "LEGAL_ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法律咨询"])},
  "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常问问题"])},
  "NEXT_DRAW_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下期数"])},
  "NEXT_DRAW_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下期开奖"])},
  "ENGLISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文"])},
  "CHINESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "RIGHTS_RESERVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留所有权利"])},
  "DRAWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已开"])},
  "DRAWN2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期"])},
  "LEFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩"])},
  "DRAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期数"])},
  "HISTORY_RESULTS_ETHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖历史：开奖号码大约每5分钟更新一次"])},
  "HISTORY_RESULTS_TRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖历史：开奖号码大约每2分钟更新一次"])},
  "DATE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "BLOCK_HEIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块高度"])},
  "NUMBERS_DRAWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖号码"])},
  "DRAW_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖时间"])},
  "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有数据"])},
  "HOW_TO_PLAY_ETHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以太时时彩 游戏玩法"])},
  "HOW_TO_PLAY_TRON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波场时时彩 游戏玩法"])},
  "ETHER_CONTENT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天从（GMT+8）00:05 到 00:00 开盘，每5分钟开一次奖，每天开奖288期，包括无开奖数据的情况。"])},
  "ETHER_CONTENT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从0-9的数字中，开出5个号码，号码可重复。"])},
  "ETHER_CONTENT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖号码依据https://etherscan.io/ 里的区块高度>哈希值而计算出来。"])},
  "ETHER_CONTENT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一个开奖时间后，最快收到的特定区块高度，将使用它的哈希值作为开奖结果。"])},
  "ETHER_CONTENT4_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：如果区块 #14877629的哈希值是“0xe03b8b3afcea69500408226e4d8b81c60a9879c750b36ea047fad9”"])},
  "ETHER_CONTENT4_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统会选出哈希值的最后5个数字作为开奖数据。这个案例的哈希值最后5个数字为：3,0,6,9,3"])},
  "ETHER_CONTENT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此，开奖号码为"])},
  "ETHER_CONTENT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有可能发生无开奖数据的情况。"])},
  "ETHER_CONTENT6_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果00:05:00这一期的开奖数据在00:05:00~00:09:59有生成区块高度，这一期是成立的。"])},
  "ETHER_CONTENT6_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果00:05:00这一期的开奖数据在00:05:00~00:09:59没有生成区块高度，这一期为无开奖数据；如果区块高度生成在00:10:00，则属于下一期的开奖数据。"])},
  "ETHER_CONTENT7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何验证开奖结果:"])},
  "ETHER_CONTENT7_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击开奖页面里的区块高度号码，会链接到https://etherscan.io/。"])},
  "ETHER_CONTENT7_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保区块高度与你要查询的区块高度一致。"])},
  "ETHER_CONTENT7_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击更多来查询哈希号码。"])},
  "ETHER_CONTENT7_C_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向下滚动单击查看更多检查哈希值。"])},
  "ETHER_CONTENT7_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要更换语言，滑到页面尾端，选择 ”偏好设定“。"])},
  "ETHER_CONTENT7_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在”语言“部分，选择你要的语言。"])},
  "ETHER_CONTENT7_F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击”保存首选项“就能更换语言。"])},
  "TRON_CONTENT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天从（GMT+8）00:02 到 00:00 开盘，每2分钟开一次奖，每天开奖720期，包括无开奖数据的情况。"])},
  "TRON_CONTENT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从0-9的数字中，开出5个号码，号码可重复。"])},
  "TRON_CONTENT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖号码依据https://tronscan.org/ 里的区块高度>哈希值而计算出来。"])},
  "TRON_CONTENT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在下一个开奖时间后，最快收到的特定区块高度，将使用它的哈希值作为开奖结果。"])},
  "TRON_CONTENT4_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例：如果区块 #41145038 的哈希值是“000000000273d2ce68de4c924e269139a6ad6a4c42a260bd7b41d4da3a2"])},
  "TRON_CONTENT4_A_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统会选出哈希值的最后5个数字作为开奖数据。这个案例的哈希值最后5个数字为：5,2,1,9,3"])},
  "TRON_CONTENT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此，开奖号码为"])},
  "TRON_CONTENT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有可能发生无开奖数据的情况。"])},
  "TRON_CONTENT6_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果00:02:00这一期的开奖数据在00:02:00~00:03:59有生成区块高度，这一期是成立的。"])},
  "TRON_CONTENT6_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果00:02:00这一期的开奖数据在00:02:00~00:03:59没有生成区块高度，这一期为无开奖数据；如果区块高度生成在00:04:00，则属于下一期的开奖数据。"])},
  "TRON_CONTENT7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何验证开奖结果:"])},
  "TRON_CONTENT7_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击开奖页面里的区块高度号码，会链接到https://tronscan.org/。"])},
  "TRON_CONTENT7_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保区块高度与你要查询的区块高度一致。"])},
  "TRON_CONTENT7_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击区块哈希值旁的复制按钮，粘贴在笔记就能查询完整的哈希值。"])},
  "TRON_CONTENT7_C_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击查看更多检查哈希值。"])},
  "TRON_CONTENT7_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要更换语言，滑到页面尾端，选择你要的语言。"])},
  "TRON_CONTENT7_D_MOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要更换语言，点击上方菜单。"])},
  "TRON_CONTENT7_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择你要的语言"])},
  "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一"])},
  "TU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二"])},
  "WE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三"])},
  "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四"])},
  "FRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五"])},
  "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六"])},
  "SU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "JAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一月"])},
  "FEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二月"])},
  "MAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三月"])},
  "APR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四月"])},
  "MAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五月"])},
  "JUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六月"])},
  "JUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七月"])},
  "AUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八月"])},
  "SEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九月"])},
  "OCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十月"])},
  "NOV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十一月"])},
  "DEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十二月"])},
  "JAN_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一月"])},
  "FEB_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二月"])},
  "MAR_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三月"])},
  "APR_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四月"])},
  "MAY_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五月"])},
  "JUN_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["六月"])},
  "JUL_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七月"])},
  "AUG_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八月"])},
  "SEPT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["九月"])},
  "OCT_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十月"])},
  "NOV_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十一月"])},
  "DEC_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十二月"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "SET_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
  "SET_DATE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "WHENLOTTERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当区块链遇上彩票"])},
  "23HOURSADAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23小时无间断娱乐，每2分钟&5分钟开彩"])},
  "PICK5LUCKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选5个幸运号码就可以赢大奖"])}
}