import { defaultLocale } from "@/js/config";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: `/${defaultLocale}`,
  },
  {
    path: "/:locale",
    component: () => import("../pages/MainPage.vue"),
    redirect: `/${defaultLocale}`,
    children: [
      {
        name: "Home",
        path: "",
        component: () => import("../pages/HomePage.vue"),
      },
      {
        name: "GameDetail",
        path: ":game",
        component: () => import("../pages/GameDetail.vue"),
      },
      {
        name: "GameInfo",
        path: ":game/:gameinfo",
        component: () => import("../pages/GameInfo.vue"),
      },
      // {
      //   name: "GameMenu",
      //   path: "menu",
      //   component: () => import("../pages/MenuPage.vue"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
