/* eslint-disable no-debugger */
// import { defaultLocale } from "@/js/config";
import api from "@/js/api";
import router from "@/router";
import dayjs from "dayjs";
import { createStore } from "vuex";

const store = createStore({
  state: {
    isMobile: true,
    isMobileMenu: false,
    isGameHeader: false,
    isInfoHeader: false,
    isLocale: "en",
    currentGame: "ether",
    // playList: false,
    // gameList: false,
    // localeList: false,
    selectedMenu: "",
    gamePage: 1,
    calendar: {
      show: false,
      selectedDate: dayjs().format("YYYY-MM-DD"),
    },
    cryptoList: [
      {
        name: "ETHERSCAN",
        game: "ether",
        img: require("../../global/img/ether-logo.png"),
        mins: 5,
      },
      {
        name: "TRONSCAN",
        game: "tron",
        img: require("../../global/img/tron-logo.png"),
        mins: 2,
      },
    ],
  },
  mutations: {
    deviceDetectHandler(state, data) {
      state.isMobile = data;
    },
    showMenuListHandler(state, data) {
      if (data === "clear" || data === state.selectedMenu) {
        state.selectedMenu = "";
      } else state.selectedMenu = data;
    },
    mobileMenuHandler(state) {
      state.isMobileMenu = !state.isMobileMenu;
    },
    gameHeaderHandler(state) {
      if (state.isInfoHeader) {
        state.isInfoHeader = !state.isInfoHeader;
      }
      state.isGameHeader = !state.isGameHeader;
    },
    infoHeaderHandler(state) {
      if (state.isGameHeader) {
        state.isGameHeader = !state.isGameHeader;
      }
      state.isInfoHeader = !state.isInfoHeader;
    },
    changeLocaleHandler(state, data) {
      if (data) {
        let locale = data;

        if (typeof window !== "undefined") {
          let fullPath = window.location.pathname.split("/");
          fullPath[1] = locale;

          router.push(fullPath.join("/"));
        }
        state.isLocale = locale;
      } else state.isLocale = "en";
    },
    gamePageHandler(state, data) {
      state.gamePage = data;
    },
    selectDateHandler(state, data) {
      state.calendar.selectedDate = data;
    },
    showCalendarHandler(state, data) {
      if (data === "clear") {
        state.calendar.show = false;
      } else state.calendar.show = !state.calendar.show;
    },
    setCurrentGame(state, data) {
      state.currentGame = data;
    },
  },
  getters: {
    isMobile: (state) => state.isMobile,
    isMobileMenu: (state) => state.isMobileMenu,
    isGameHeader: (state) => state.isGameHeader,
    isInfoHeader: (state) => state.isInfoHeader,
    isLocale: (state) => state.isLocale,
    gamePage: (state) => state.gamePage,
    calendar: (state) => state.calendar,
    cryptoList: (state) => state.cryptoList,
    // playList: (state) => state.playList,
    // gameList: (state) => state.gameList,
    // localeList: (state) => state.localeList,
    selectedMenu: (state) => state.selectedMenu,
    currentGame: (state) => state.currentGame,
  },
  actions: {
    deviceDetectHandler({ commit }, data) {
      commit("deviceDetectHandler", data);
    },
    mobileMenuHandler({ commit }) {
      commit("mobileMenuHandler");
    },
    gameHeaderHandler({ commit }) {
      commit("gameHeaderHandler");
    },
    infoHeaderHandler({ commit }) {
      commit("infoHeaderHandler");
    },
    changeLocaleHandler({ commit }, data) {
      commit("changeLocaleHandler", data);
    },
    gamePageHandler({ commit }, data) {
      commit("gamePageHandler", data);
    },
    selectDateHandler({ commit }, data) {
      commit("selectDateHandler", data);
    },
    showCalendarHandler({ commit }, data) {
      commit("showCalendarHandler", data);
    },
    showMenuListHandler({ commit }, data) {
      commit("showMenuListHandler", data);
    },
    setCurrentGame({ commit }, data) {
      commit("setCurrentGame", data);
    },

    // API Integration
    getLatestList(__, params) {
      return api.get(`/latestList`, params);
    },
    getGameHeader(__, params) {
      return api.get(`/latest`, params);
    },
    getGameList(__, params) {
      return api.get(`/history`, params);
    },
  },
});

export default store;
