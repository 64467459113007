/* eslint-disable no-debugger */
import { mapActions, mapGetters } from "vuex";
import { mobileSize } from "../config";

export default {
  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  },
  watch: {
    $route(to, from) {
      this.setCurrentLanguage(to, from);
    },
  },
  mounted() {
    this.setCurrentLanguage();
  },
  computed: {
    ...mapGetters(["isLocale"]),
  },
  methods: {
    ...mapActions(["deviceDetectHandler", "changeLocaleHandler"]),
    resizeHandler(e) {
      let deviceWidth = 0;
      if (typeof e === "undefined") {
        deviceWidth = window.innerWidth;
      } else {
        deviceWidth = e.currentTarget.innerWidth;
      }

      if (deviceWidth > mobileSize) {
        this.deviceDetectHandler(false);
      } else {
        this.deviceDetectHandler(true);
      }
    },
    setCurrentLanguage(to, from) {
      if (typeof to === "undefined") {
        const fullPath = window.location.pathname.split("/");
        this.changeLocaleHandler(fullPath[1]);
        this.$i18n.locale = fullPath[1];
      } else {
        if (to.params.locale !== from.params.locale) {
          this.changeLocaleHandler(to.params.locale);
        }
      }
    },
  },
};
