import axios from "axios";
import config from "@/js/config";

axios.defaults.withCredentials = false;
axios.defaults.timeout = 15000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.baseURL = config.apiURL;
// axios.interceptors.response.use(
//   function (response) {
//     if (response.data.result !== null) {
//       if (response.data.result.businessCode === 100007) {
//         // request timeout, user has been blocked, then remover user in localstorage reload whole page
//         localStorage.removeItem("user");
//         location.reload();
//       }
//     }

//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );
export default {
  get(url, param) {
    //change goal serve api to
    // let urlArray = url.split("/");
    // let urlArrayLength = urlArray.length;
    // let apiVersion = urlArray[1];
    // let newUrl = urlArray.slice(2, urlArrayLength).join("/");

    // if (typeof apiVersion !== "undefined" && apiVersion === "v2") {
    //   url = "/" + newUrl;
    //   axios.defaults.baseURL = config.apiUrlV2;
    // } else {
    //   axios.defaults.baseURL = config.apiUrl;
    // }

    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: param })
        .then((response) => {
          //console.log(response.data)
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.data);
        });
    });
  },
};
