<template>
  <router-view></router-view>
</template>

<script>
// import { mapGetters } from "vuex";
import InitMixin from "@/js/mixin/InitMixin";
export default {
  components: {},

  mixins: [InitMixin],
};
</script>
